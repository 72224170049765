/*-----------------------------------*\
  #style.css
\*-----------------------------------*/



/*-----------------------------------*\
  #CUSTOM PROPERTY
\*-----------------------------------*/

:root {

  /**
   * colors
   */

  --granite-gray: hsl(210, 1%, 37%);
  --eerie-black: hsl(240, 2%, 11%);
  --slimy-green: hsl(91, 100%, 30%);
  --fuzzy-wuzzy: hsl(0, 42%, 57%);
  --davys-gray: hsl(220, 2%, 28%);
  --cultured-1: hsl(228, 17%, 94%);
  --cultured-2: hsl(0, 0%, 96%);
  --dim-gray: hsl(240, 1%, 41%);
  --white_80: hsla(0, 0%, 100%, 0.8);
  --white_70: hsla(0, 0%, 100%, 0.7);
  --white_40: hsla(0, 0%, 100%, 0.4);
  --white_10: hsla(0, 0%, 100%, 0.1);
  --black_05: hsla(0, 0%, 0%, 0.05);
  --black_50: hsla(0, 0%, 0%, 0.5);
  --black_25: hsla(0, 0%, 0%, 0.25);
  --white-2: hsl(0, 0%, 98%);
  --white-1: hsl(0, 0%, 100%);
  --black: hsl(0, 0%, 0%);

  /**
   * theme colors
   */

  --hue: 227;
  --theme-bg: linear-gradient(to bottom,
      hsl(var(--hue, 227), 40%, 50%),
      hsl(var(--hue, 227), 40%, 60%));
  --theme-color: hsl(var(--hue, 227), 42%, 57%);

  /**
   * typography
   */

  --ff-roboto: "Roboto", sans-serif;

  --fs-1: 2.6rem;
  --fs-2: 2.2rem;
  --fs-3: 1.8rem;
  --fs-4: 1.4rem;
  --fs-5: 1.2rem;

  --fw-500: 500;

  /**
   * radius
   */

  --radius-4: 4px;
  --radius-5: 5px;
  --radius-10: 10px;

  /**
   * shadow
   */

  --shadow-1: 0 0 5px hsla(0, 0%, 0%, 0.05);
  --shadow-2: 0 0 10px hsla(0, 0%, 0%, 0.05);

  /**
   * transition
   */

  --transition: 0.15s ease;
  --cubic-out: cubic-bezier(0.05, 0.65, 0.25, 1);

}





/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

a,
img,
span,
input,
label,
button,
strong,
ion-icon {
  display: block;
}

img {
  height: auto;
}

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input {
  width: 100%;
}

button {
  cursor: pointer;
}

strong {
  font-weight: normal;
}

ion-icon {
  pointer-events: none;
}

html {
  font-family: var(--ff-roboto);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-image: var(--theme-bg);
  color: var(--eerie-black);
  font-size: 1.6rem;
  min-height: 100vh;
  padding-block: 30px;
  display: grid;
  grid-template-columns: 1fr;
  overflow: hidden;
  opacity: 0;
}

/* add class - loaded */
body {
  opacity: 1;
  transition: 0.3s ease;
}

:focus-visible {
  outline-offset: 4px;
}

:focus-visible:active {
  outline-offset: 2px;
}

/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 20px; }

.container,
.main,
.app-box { height: 100%; }

.h1,
.h2 { font-weight: var(--fw-500); }

.h1 { font-size: var(--fs-1); }

.h2 { font-size: var(--fs-3); }

/*-----------------------------------*\
  #MEDIA QUERIES
\*-----------------------------------*/

/**
 * responsive for larger than 550px screen
 */

 @media (min-width: 550px) {

  /**
   * CUSTOM PROPERTY
   */

  :root {

    /**
     * typography
     */

    --fs-1: 3rem;
    --fs-3: 2.2rem;
    --fs-5: 1.4rem;

  }



  /**
   * HEADER
   */

  .dropdown-container { width: 280px; }

  .theme-btn-group { grid-template-columns: repeat(5, 1fr); }



  /**
   * MAIN
   */

  .title-wrapper .img { width: 100px; }

}





/**
 * responsive for larger than 768px screen
 */

@media (min-width: 768px) {

  /**
   * REUSED STYLE
   */

  .container {
    max-width: 650px;
    width: 100%;
    margin-inline: auto;
  }



  /**
   * HEADER
   */

  .app-box {
    background-color: var(--white_10);
    padding: 25px;
    border-radius: var(--radius-10);
  }



  /**
   * MAIN
   */
  
  .task-list { max-height: calc(100vh - 250px); }

  .input-wrapper {
    bottom: 25px;
    left: 25px;
    right: 25px;
  }

}